import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { StorageManagerService } from '../../core/authentication/storage-manager.service';

@Injectable({
    providedIn: 'root',
})
export class HelperService {
    constructor() {}

    isSuperAdmin(): boolean {
        const isAdmin = StorageManagerService.getUser()?.isAdmin;
        if (isAdmin == 'YES') {
            return true;
        } else {
            return false;
        }
    }
    showPrice(): boolean {
        const isPrice = StorageManagerService.getUser()?.viewProductPrice;
        if (isPrice == 'YES') {
            return true;
        } else {
            return false;
        }
    }
}
